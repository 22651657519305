import React from 'react';
import styled from 'styled-components';
import color from 'styles/colors';
import { respondTo } from 'styles/helper';
import { useLanguageStrings } from 'lib/hooks';
import { Brand, LINKS } from 'constants/index';
import Link from '../Link';

const Footer = (): JSX.Element => {
  const { language_strings_en_US } = useLanguageStrings();
  return (
    <StyledFooter>
      <section className="inner">
        <Link className="helpLink" external href={LINKS.HELP_LINK} target={LINKS.target}>
          {language_strings_en_US?.auth_selectProviderHelpText}
        </Link>
        <hr className="helpLink" />
        <div className="legal">
          © 2022 FOX. All Rights Reserved. Use of this website <br /> assumes acceptance of the{' '}
          {process.env.NEXT_PUBLIC_BRAND !== Brand.NATION ? (
            <Link external href={LINKS.TERMS_LINK} target={LINKS.target}>
              <span>Terms of Use.</span>
            </Link>
          ) : (
            'Terms of Use.'
          )}
          <br />
          <Link data-testid="link" external href={LINKS.DO_NOT_SELL_LINK} target={LINKS.target}>
            <span>{language_strings_en_US?.ccpa_do_not_sell_label.toUpperCase()}</span>
          </Link>
        </div>
      </section>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  display: flex;
  flex: 1;
  align-items: flex-end;
  text-align: center;
  width: 100%;
  height: 220px;
  color: ${color.white};
  font-size: 10px;
  line-height: 1.8em;
  opacity: 50%;
  .inner {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    ${respondTo.bp_767`
      margin-bottom: 75px;
    `}
  }
  hr {
    width: 100%;
    max-width: 300px;
    opacity: 50%;
  }
  a {
    display: inline-block;
    text-decoration: none;
    span {
      border-bottom: 0.5px solid;
    }
  }
  .helpLink {
    font-size: 14px;
    color: ${color.white};
    font-family: 'Graphik', serif;
    font-weight: 600;
    text-decoration: none;
  }
`;
